.map-folder-box{
  min-height: 20px;
  background-color: #359AE1;
  width: 100%;
  padding: 3px 5px 3px 10px;
  color: #FFFFFF;
}
.cb {clear: both}

.map-folder {
  width: 100% !important;
  min-height: 20px;
  background-image: url("../../../assets/icon/arrow-right.png");
  background-position: right;
  background-repeat: no-repeat;
  cursor: pointer;
}

.map-folder-box0 {
  background-color: #359AE1;
}

.map-folder-box1 {
  background-color: #61AC69;
}

.map-folder-box2 {
  background-color: #FFAF18;
}

.map-folder-box3, .map-folder-box4, .map-folder-box5, .map-folder-box6, .map-folder-box7, .map-folder-box8, .map-folder-box9,
.map-folder-box10, .map-folder-box11, .map-folder-box12, .map-folder-box13, .map-folder-box14, .map-folder-box15, .map-folder-box16{
  background-color: #FF7918;
}



.MuiPaper-root {
   padding: 0px !important;
  margin-left: 5px !important;
 }
.MuiAccordion-root {
  padding: 0px !important;
}
.MuiPaper-rounded {
  padding: 0px !important;
}
.MuiAccordionSummary-content {
  display: block !important;
  margin-bottom: 0px !important;
  margin-top: 3px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.MuiAccordionDetails-root {
  display: block !important;
  padding: 2px 2px 2px 8px !important;
}

.MuiAccordionSummary-root {
  min-height: 30px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.map-folder-box {
  background-image: url("../../../assets/icon/arrow-right-white.png");
  background-position: right;
  background-repeat: no-repeat;
  cursor: pointer;
}

.MuiAccordion-rounded {
  padding: 0px !important;
}
.MuiAccordion-root.Mui-expanded {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.MuiAccordion-rounded:last-child {
  border: none !important;
  border-radius: unset !important;
}

.MuiPaper-elevation1 {
  box-shadow: unset !important;
  padding: 0px !important;
}

.group-sub-folder {
  min-height: 25px;
  background-position: right;
  background-repeat: no-repeat;
  cursor: pointer;
}

.group-sub-close {
  
  background-image: url("../../../assets/icon/arrow-down.png");
  cursor: pointer;
  color: #3a3a3a;
  font-weight: normal;
}

.group-sub-open {
  background-image: url("../../../assets/icon/arrow-up.png");
  cursor: pointer;
  color: #1075bd;
  font-weight: 700;
}

.map-item {
  min-height: 20px;
  cursor: pointer;
  margin-left: 5px;
  cursor: pointer;
}

.map-item label {
  cursor: pointer;
}
