.main-nav {
  background: #1075bd;
  -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  color: #fff;
}

.main-nav ul {
  margin: 0 0;
  padding: 0 0;
  list-style: none;
}

.main-nav ul li {
  float: left;
  border-bottom: 3px solid  transparent;
}

.main-nav ul li:hover, .main-nav ul li.active {
  border-color: #D92027;
}

.main-nav ul li.has-child {
  position: relative;
}

.main-nav ul li.has-child svg {
  vertical-align: baseline;
  height: 13px;
}

.main-nav ul li.has-child:hover ul.level1 {
  display: block;
}

.main-nav ul li a {
  padding: 19px 12px 16px;
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
}

.main-nav ul li a:hover {
  text-decoration: none;
}

.main-nav ul li ul.level1 {
  display: none;
  position: absolute;
  top: 55px;
  left: 0;
  background: #359ae2;
  min-width: 250px;
  border-top: 3px solid  #D92027;
  z-index: 9;
}

.main-nav ul li ul.level1 li {
  float: none;
  padding: 0 15px;
  border: none;
}

.main-nav ul li ul.level1 li a {
  display: block;
  border-bottom: 1px solid #fff;
  padding: 12px 0;
}

.main-nav ul li ul.level1 li:last-child a {
  border: none;
}

.main-nav ul::after {
  content: "";
  display: block;
  clear: both;
}

@media (max-width: 1199px) {
  .main-nav .container {
    position: relative;
  }
  .main-nav .menu-mobile {
    display: inline-block;
    padding: 10px 0;
  }
  .main-nav .menu {
    display: none;
    position: absolute;
    top: 40px;
    right: 0;
    left: 0;
    background: #FFAF18;
    border-top: 1px solid #fff;
    z-index: 9;
  }
  .main-nav .menu ul {
    padding: 0 15px;
  }
  .main-nav .menu ul li {
    float: none;
    border-bottom: 1px solid #fff;
  }
  .main-nav .menu ul li:last-child {
    border: none;
  }
  .main-nav .menu ul li.has-child a {
    position: relative;
  }
  .main-nav .menu ul li.has-child a svg {
    position: absolute;
    right: 0;
    top: 10px;
    height: 15px;
    width: 15px;
  }
  .main-nav .menu ul li a {
    padding: 8px 0;
    font-size: 14px;
  }
  .main-nav .menu ul li ul.level1 {
    position: relative;
    border: none;
    top: auto;
    left: auto;
  }
  .main-nav .menu ul li ul.level1 li a {
    border: none;
  }

  .main-nav .menu ul li ul.level1_ext {
    position: relative;
    border: none;
    top: auto;
    left: auto;
  }

  .main-nav .menu .login-icon {
    border-bottom: none !important; margin-top: 2px; margin-bottom: 2px;
  }
}

@media(max-width: 1024px) {
  .main-nav ul li a {
    font-size: 13px;
  }
  
}

.level1_ext {
  min-width: 140px !important;
  max-width: 140px !important;
}
