aside {
    .block {
        border-radius: 3px;
        padding: 5px 0;

        img {
            max-width: 100%;
            width: 100%;
            -webkit-transition: .4s;
            transition: .4s;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            object-fit: cover;
          
            opacity: 1;
            display: block;
            width: 100%;
            height: auto;
            transition: .5s ease;
            backface-visibility: hidden;
          }
    }
}

@media (max-width: 1024px) {
    aside {
        .block {
            border-radius: 3px;
            padding: 5px 0px 5px;
    
            // img {
            //     max-width: 100%;
            //     width: 100%;
            //     -webkit-transition: .4s;
            //     transition: .4s;
            //     border-top-left-radius: 4px;
            //     border-top-right-radius: 4px;
            //     object-fit: cover;
            
            //     opacity: 1;
            //     display: block;
            //     width: 100%;
            //     height: auto;
            //     transition: .5s ease;
            //     backface-visibility: hidden;
            // }
        }
    }
}
  