.no-data-for-display {text-align: center; margin-top: 20px;}
.reset-news-bg {background-color: #FFFFFF;}
.news-create-date {margin-bottom: 10px; font-size: 12px; color: #CCCCCC;}
.news-description {min-height: 20px; text-align: justify}
.news img {max-width: 98%;}
.news figure {text-align: center;}
.community-opinion {
  //border: 1px solid #f6f6f6;

  .header-doing {
    height: 2.5rem;
    background-color: #1075bd;

    .title {
      color: #ffffff;
      font-size: 1em;
      font-weight: 500;
    }

    .input-search {
      width: 14.5rem;
      padding-left: 10px;
      padding-right: 30px;
      height: 1.8rem;
      border: 1px solid #ffffff;
      border-radius: 2px;
      position: relative;
      font-size: 0.9em;
      outline: none;
    }

    .icon-search {
      position: absolute;
      right: 1.4rem;
      top: 0.7rem;
      font-size: 1.1rem;
      color: #888;
      cursor: pointer;
    }

    .btn {
      font-size: 0.9rem;
    }

    .dropdown-menu {
      border: unset;
      border-radius: 0;
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    }
  }

  .content-doing {
    margin-top: 1rem;

    .first-item {
      img {
        height: 31.25rem;
        object-fit: cover;
      }

      .title {
        text-decoration: none;
        color: #000000;
        font-size: 20px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .item {
      margin-bottom: 2rem;

      img {
        height: 10rem;
        object-fit: cover;
      }

      .title {
        text-decoration: none;
        color: #000000;
        font-size: 20px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .header-result {
    height: 2.5rem;
    background-color: #1075bd;
    padding: 0.375rem;

    .title {
      padding-top: 0.2rem;

      a {
        text-decoration: none;
        color: #ffffff;
        font-size: 1em;
        font-weight: 500;
        padding-left: 1rem;

        &:hover {
          color: #ffffff;
        }
      }
    }
  }

  .content-result {
    margin-top: 1rem;

    .item {
      margin-bottom: 1rem;
      padding-right: 0.5rem;

      img {
        height: 6.25rem;
        object-fit: cover;
      }

      a {
        text-decoration: none;
        font-size: 1em;
        font-weight: 600;
        color: #555;
      }
    }
  }

  .list-footer {
    border-top: 1px solid #b6b3b3;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .text-result {
      color: #c22e3c;
      font-weight: 600;
    }

    .pagination {
      border-radius: unset;

      .page-item {
        .page-link {
          border: 0;
          border-radius: 50%;
          color: #444444;
          padding: 6px 12px;
        }

        &.active {
          .page-link {
            background-color: #1075bd;
            margin: 0 2px;
            color: #ffffff;
          }
        }

        &.disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.news {
	.list-hot-news {
    .item-wrapper {
      box-shadow: 0 1px 10px 0 rgba(0,0,0,.14902);
      background-color: #fff;
    }
    
    .news-item-title {
      cursor: pointer;
    }

    .news-item-date {
      margin-bottom: 10px;
      // font-size: 12px;
      color: #9e9797;  
    }

    .news-item-desc {
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .news-item-image {
      text-align: center;
      position: relative;
      display: flex;

      img {
        max-width: 100%;
        width: 100%;
        -webkit-transition: .4s;
        transition: .4s;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        object-fit: cover;
      
        opacity: 1;
        display: block;
        width: 100%;
        height: auto;
        transition: .5s ease;
        backface-visibility: hidden;
      }

      .middle {
        transition: .5s ease;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);

        .text {
          background-color: #1075BD;
          color: white;
          font-size: 13px;
          padding: 13px 26px;
          cursor: pointer;
        }
      }
    }

    .news-item-image:hover {
      img {
        opacity: 0.6;
      }

      .middle {
        opacity: 1;
      }

    }
	}
}

@media(max-width: 1024px) {
  .news {
    .list-hot-news {
      .item-wrapper {
        box-shadow: 0 1px 10px 0 rgba(0,0,0,.14902);
        background-color: #fff;
      }
      
      .news-item-title {
        cursor: pointer;
        font-size: 1rem;
      }

      .news-item-date {
        margin-bottom: 0;
      }

      .news-item-desc {
        -webkit-line-clamp: 2;
      }

      .news-item-image {
        flex: 0 0 100%;
        max-width: 100%;
      }
      
      .news-item-body {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

#list-news-header-box {
  width: 100% !important;
}


@media (max-width: 767px) {
  .news-list #search {
    width: 228px !important;
  }
  .news .other-news .new-item .new-image {
    width: 100%;
    text-align: center;
    height: auto;
    margin-bottom: 10px;
  }
  .list-other-news .new-image img {
    height: auto;
  }
  .list-other-news .new-info {
    width: 100%;
    margin:auto !important;
    float: left;
  }

  .list-other-news .new-info .news-create-date {
    font-size: 16px;
  }
}