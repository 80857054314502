.menu-schedule {margin-top: 10px;}
.planning-announcement .content-result .item-map img {
  max-height: none !important;
}

@media(max-width: 1024px) {
  .community-opinion{
    .header-result {
      .title {
        a {
          font-size: 0.88em !important;
        }
      }
    }
  }
}