footer {
  background: #fee67c;
	color: black;
  .footer-nav-wrapper {
    background: url(/images/bg-footer.jpg) repeat-x left center;
    min-height: 53px;
    background-size: contain;
  }
  .info {
    .name {
      padding: 15px 0px 10px;
      display: flex;
      justify-content: center!important;
      align-items: center;
      &:after {
        clear: both;
        display: block;
        content: "";
      }
      img {
        margin-right: 15px;
        margin-top: 5px;
      }
      span {
        // vertical-align: top;
        // display: inline-block;
        font-weight: 700;
        font-size: 15px;
        line-height: 24px;
        text-transform: uppercase;
        margin: 12px 0 0;
      }
    }
    .des {
      text-align: center;
      font-size: 14px;
		  line-height: 16px;
    }
    .detail {
      p {
        margin-bottom: 2px;
        font-size: 16px;
        line-height: 26px;
        svg {
          width: 14px;
          margin-right: 6px;
          vertical-align: middle;
        }
      }
    }
  }

	.copyright {
		border-top: 1px solid #fff;
		padding: 12px 0;
		font-size: 14px;
		line-height: 16px;
	}
}
