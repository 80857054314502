.news {
  // background: #EEEEEE;
  padding: 30px 10px;
  // padding-bottom: 30px;
}

.news .new-nav .nav-action {cursor: pointer;}
.new-image {text-align: center}

.news h3 {
  font-size: 30px;
  color: #1075bd;
}

.news .new-nav {
  text-align: right;
}

.news .new-nav .nav-action {
  display: inline-block;
  width: 22px;
  line-height: 22px;
  height: 22px;
  border: 1px solid #333333;
  text-align: center;
  margin-left: 5px;
}

.news .new-nav .nav-action svg {
  vertical-align: middle;
  height: 20px;
}

.news .hot-new {
  box-shadow: 0 1px 10px 0 rgba(0,0,0,.14901960784313725);
  background-color: #FFFFFF;
}

.news .hot-new .new-image {
  text-align: center;
  position: relative;
}

.news .hot-new .new-image img {
  max-width: 100%;
  width: 100%;
  -webkit-transition: .4s;
  transition: .4s;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  object-fit: cover;
}

.news .hot-new .new-title {
  // font-weight: 500;
  // font-size: 18px;
  line-height: 21px;
  color: #333333;
  display: block;
  margin: 10px 0 5px;
}

.news .hot-new .new-date {
  font-size: 16px;
  line-height: 19px;
  color: #A4A4A4;
  margin-bottom: 10px;
}

.news .hot-new .new-desc {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
  text-align: justify;
}

.news .hot-new .view-detail {
  color: #1075bd;
  font-size: 16px;
  line-height: 19px;
}

.news .hot-new .view-detail svg {
  vertical-align: unset;
  margin-left: 5px;
}

.news .hot-new .view-detail:hover {
  text-decoration: none;
}

.news .other-news {
  // margin-bottom: 30px;
}

.news .other-news .new-item {
  border-radius: 8px;
  background: white;
  // padding: 5px 10px;
  box-shadow: 0 1px 10px 0 rgba(0,0,0,.14901960784313725);
  margin-bottom: 0;
}

.news .other-news .new-item:after {
  content: "";
  display: block;
  clear: both;
}

.news .other-news .new-item:last-child {
  margin-bottom: 0;
}

.news .other-news .new-item .new-image {
  overflow: hidden;
}

.news .other-news .new-item .new-image img {
  max-width: 100%;
  width: 100%;
  -webkit-transition: .4s;
  transition: .4s;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 150px;
  object-fit: cover;
}

.news .other-news .new-item .new-info {
  padding: 8px 10px 14px;
}

.news .other-news .new-item .new-info .new-title {
  // font-weight: 500;
  // font-size: 16px;
  // line-height: 19px;
  // color: #333;
  // display: block;
  // margin-bottom: 6px;

  // font-size: 16px;
  // font-weight: 500;
  line-height: 21px;
  color: #333;

  margin-bottom: 5px;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.news .other-news .new-item .new-info .new-date {
  font-size: 16px;
  line-height: 19px;
  color: #A4A4A4;
  margin-bottom: 28px;
}

.news .other-news .new-item .new-info .view-detail {
  color: #1075bd;
  font-size: 16px;
  line-height: 19px;
}

.news .other-news .new-item .new-info .view-detail svg {
  vertical-align: unset;
  margin-left: 5px;
}

.news .other-news .new-item .new-info .view-detail:hover {
  text-decoration: none;
}

@media (max-width: 767px) {
  #home-other-news .new-image img {
    height: auto;
  }
  #home-other-news .new-info {
    width: 100%;
    margin:auto !important;
    float: left;
  }

  #home-other-news .new-info .news-create-date {
    font-size: 16px;
  }

  #home-other-news .view-detail {
    float: right;
    margin-right: 10px;
  }
}


//home 
.news {
  .hot-new {
    .new-image-home {
      text-align: center;
      position: relative;

      img {
        max-width: 100%;
        width: 100%;
        -webkit-transition: .4s;
        transition: .4s;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        object-fit: cover;
      
        opacity: 1;
        display: block;
        width: 100%;
        height: auto;
        transition: .5s ease;
        backface-visibility: hidden;
      }

      .middle {
        transition: .5s ease;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);

        .text {
          background-color: #1075BD;
          color: white;
          font-size: 16px;
          padding: 16px 32px;
          cursor: pointer;
        }
      }
    }

    .new-image-home:hover {
      img {
        opacity: 0.6;
      }

      .middle {
        opacity: 1;
      }

    }

    .new-desc-home {
      margin-bottom: 16px;
      text-align: justify;
      
      font-size: 16px;
      line-height: 19px;
      color: #333;
    
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .other-news {
    .new-item {
      .new-image-home {
        text-align: center;
        position: relative;
        min-height: 178px;
  
        img {
          max-width: 100%;
          width: 100%;
          -webkit-transition: .4s;
          transition: .4s;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          object-fit: cover;
        
          opacity: 1;
          display: block;
          width: 100%;
          height: auto;
          transition: .5s ease;
          backface-visibility: hidden;
        }
  
        .middle {
          transition: .5s ease;
          opacity: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
  
          .text {
            background-color: #1075BD;
            color: white;
            font-size: 13px;
            padding: 13px 26px;
            cursor: pointer;
          }
        }
      }
  
      .new-image-home:hover {
        img {
          opacity: 0.6;
        }
  
        .middle {
          opacity: 1;
        }
  
      }

      .new-desc-home {
        margin-bottom: 16px;
        text-align: justify;
        
        font-size: 16px;
        line-height: 19px;
        color: #333;
      
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }

  @media(max-width: 1024px) {
    .other-news {
      .new-item {
        .new-info {
          .new-title {
            -webkit-line-clamp: 2;
            font-size: 18px;
          }
  
          .new-desc-home {
            margin-bottom: 0;
            text-align: justify;
            
            font-size: 14px;
            line-height: 19px;
            color: #333;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
  }
}