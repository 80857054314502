.banner {
  // background: url(./slider-2.jpg) no-repeat top center;
}

.banner h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 60px;
  text-align: right;
  text-transform: uppercase;
  color: #1075bd;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
  padding: 100px 0 210px;
  margin: 0 0;
}

.banner .mini-text{
  font-weight: 400;
  font-size: 24px;
  line-height: 60px;
  color: black;
}

.slider-home-page {
  .slick-slider {
    .slick-dots {
      bottom: 30px;

      li {
        width: 10px;
        height: 10px;
        margin: 3px;

        button {
          width: 12px;
          height: 12px;
          background-color: rgba(0, 0, 0, 0);
          border: 1px solid #fff;
          border-radius: 50%;
          padding: 4px;
        }

        button::before {
          font-size: 12px;
          width: 12px;
          height: 12px;
          opacity: 0;
          color: #fff;
          line-height: 15px;
        }

        &.slick-active {
          button::before {
            opacity: 1;
            color: #fff;
          }
        }
      }
    }
  }
}