.navigation {
	padding: 10px 0 0;
	.navigation-item {
		padding: 30px 0;
		text-align: center;
		background: #1075bd;
		border-radius: 3px;
		margin-bottom: 30px;
		a {
			&:hover {
				text-decoration: none;
			}
		}
		span {
			display: block;
			font-weight: bold;
			font-size: 20px;
			line-height: 23px;
			text-align: center;
			color: #FFFFFF;
			margin-top: 10px;

			display: -webkit-box;
			text-overflow: ellipsis;
			overflow: hidden;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
		}
	}
}



@media (max-width: 1024px) {
  .navigation {
    padding: 5px 0 0;
    .navigation-item-custom {
      flex: 1;
      flex: 0 0 25%;
      .navigation-item {
        padding: 10px 10px;
        margin: 0;

        img{
          width: 65px;
        }
        span {
          font-size: 17px;
          margin-top: 5px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
	.navigation {
	  padding: 20px 0 0;
	  .navigation-item-custom {
		flex: 0 0 100%;
		max-width: 100%;
		min-height: 150px;
		margin-bottom: 5px;
		.navigation-item {
		  padding: 10px 10px;
		  margin: 0;
		  min-height: 150px;

		  img{
			width: 65px;
		  }
		  span {
			font-size: 17px;
			margin-top: 5px;
		  }
		}
	  }
	}
  }