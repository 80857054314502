.news {
    .detail-title {
        margin-bottom: 10px;
        .news-title { 
            color: #1d2124;
        }
    }

	.hot-new {
		.new-date {
			margin-top: 10px;
		}
	}

    .news-detail {
        // box-shadow: 0 1px 10px 0 rgba(0,0,0,.14902);
        // background-color: #fff;
        .news-image {
            text-align: center;
            color: #9e9797;
            font-style: italic;
            img {
                max-width: 100%;
                width: 100%;
                -webkit-transition: .4s;
                transition: .4s;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                object-fit: cover;
            }
        }

        .news-info {
            // padding: 5px 10px;
        }
    }

	.other-news {
		.news-item-title {
            cursor: pointer;
            color: black;
            text-decoration: none;
        }
    
        .news-item-date {
            margin-bottom: 10px;
            color: #9e9797;  
        }
    
        .news-item-desc {
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    
        .news-item-image {
            text-align: center;
            position: relative;
            display: flex;
    
            img {
            max-width: 100%;
            width: 100%;
            -webkit-transition: .4s;
            transition: .4s;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            object-fit: cover;
            
            opacity: 1;
            display: block;
            width: 100%;
            height: auto;
            transition: .5s ease;
            backface-visibility: hidden;
            }
    
            .middle {
                transition: .5s ease;
                opacity: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
    
            .text {
                background-color: #1075BD;
                color: white;
                font-size: 13px;
                padding: 13px 26px;
                cursor: pointer;
                text-decoration: none;
            }
            }
        }
    
        .news-item-image:hover {
            img {
            opacity: 0.6;
            }
    
            .middle {
            opacity: 1;
            }
    
        }
	}
}

@media(max-width: 1024px) {
    .news {
      .other-news {
        .news-item-title {
          cursor: pointer;
          font-size: 1rem;
        }
  
        .news-item-date {
          margin-bottom: 0;
        }
  
        .news-item-desc {
          -webkit-line-clamp: 2;
        }
  
        .news-item-image {
          flex: 0 0 100%;
          max-width: 100%;
        }
        
        .news-item-body {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }

  }